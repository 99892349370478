@import "../../common/scss/mixins";

.title-block {
  background: #FFFFFF;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  height: 90px;

  @include for-phone-only {
    flex-direction: column;
    padding: 10px 32px;
    height: auto;
  }

  &__text {
    margin-left: 20px;
    align-self: flex-start;
    margin-top: 27px;

    @include for-phone-only {
      margin-left: 0;
      text-align: center;
      margin-top: 10px;
    }
  }

  &__op-80 {
    opacity: 0.8;
  }

  &__logo {
    display: block;
    width: 170px;
    height: 60px;
    pointer-events: none;
  }
}