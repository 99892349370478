.registration {
  &__button {
    background: none;
    border: none;
    outline: none;
    border-radius: 0;
    cursor: pointer;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    font-family: inherit;

    &-text {
      font-size: 14px;
      font-weight: 400;
      line-height: 50px;
      color: #fff;
    }

    &-icon {
      margin-left: 10px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      color: #141526;
      background-color: #fff;
      transition: background-color 0.2s;

      svg {
        display: block;
        width: 20px;
        height: 20px;
        pointer-events: none;
        stroke: currentColor;
        transition: fill 0.2s, stroke 0.2s;
      }
    }


    //&:focus &-icon {
    //  background-color: #27b4b8;
    //  color: #fff;
    //}
  }
}
