.button {
  background: #23B4BA;
  border-radius: 4px;
  font-family: Supermolot;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 290px;
  height: 50px;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: #27B4B8;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25), inset 0px 0px 10px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
  }

  &:active {
    background: #1E878B;
    border-radius: 4px;
    box-shadow: none;
  }

  &_disabled {
    pointer-events: none;
  }

  &_width {
    &_auto {
      width: auto;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &_type {
    &_center {
      text-align: center;
    }
  }
}

.button-link {
  font-family: Ubuntu;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #27B4B8;
  background: transparent;
  padding: 0;
  height: 0;
  display: inline-block;
  text-decoration: underline;
}