@import '~common/scss/mixins';

.carousel {
  display: inline-flex;
  vertical-align: top;
  justify-content: space-between;

  @include tablet {
    display: flex;
  }
}