/* This stylesheet generated by Transfonter (https://transfonter.org) on June 26, 2017 3:49 PM */

@font-face {
	font-family: 'Supermolot';
	src: url('Supermolot.eot');
	src: local('Supermolot'),
		url('Supermolot.eot?#iefix') format('embedded-opentype'),
		url('Supermolot.woff') format('woff'),
		url('Supermolot.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Supermolot';
	src: url('Supermolot-BoldItalic.eot');
	src: local('Supermolot Bold Italic'), local('Supermolot-BoldItalic'),
		url('Supermolot-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('Supermolot-BoldItalic.woff') format('woff'),
		url('Supermolot-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Supermolot';
	src: url('Supermolot-LightItalic.eot');
	src: local('Supermolot Light Italic'), local('Supermolot-LightItalic'),
		url('Supermolot-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('Supermolot-LightItalic.woff') format('woff'),
		url('Supermolot-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Supermolot';
	src: url('Supermolot-Black.eot');
	src: local('Supermolot Black'), local('Supermolot-Black'),
		url('Supermolot-Black.eot?#iefix') format('embedded-opentype'),
		url('Supermolot-Black.woff') format('woff'),
		url('Supermolot-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Supermolot';
	src: url('Supermolot-Italic.eot');
	src: local('Supermolot Italic'), local('Supermolot-Italic'),
		url('Supermolot-Italic.eot?#iefix') format('embedded-opentype'),
		url('Supermolot-Italic.woff') format('woff'),
		url('Supermolot-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Supermolot';
	src: url('Supermolot-Light.eot');
	src: local('Supermolot Light'), local('Supermolot-Light'),
		url('Supermolot-Light.eot?#iefix') format('embedded-opentype'),
		url('Supermolot-Light.woff') format('woff'),
		url('Supermolot-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Supermolot';
	src: url('Supermolot-Bold.eot');
	src: local('Supermolot Bold'), local('Supermolot-Bold'),
		url('Supermolot-Bold.eot?#iefix') format('embedded-opentype'),
		url('Supermolot-Bold.woff') format('woff'),
		url('Supermolot-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Supermolot';
	src: url('Supermolot-BlackItalic.eot');
	src: local('Supermolot Black Italic'), local('Supermolot-BlackItalic'),
		url('Supermolot-BlackItalic.eot?#iefix') format('embedded-opentype'),
		url('Supermolot-BlackItalic.woff') format('woff'),
		url('Supermolot-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Supermolot';
	src: url('Supermolot-Thin.eot');
	src: local('Supermolot Thin'), local('Supermolot-Thin'),
		url('Supermolot-Thin.eot?#iefix') format('embedded-opentype'),
		url('Supermolot-Thin.woff') format('woff'),
		url('Supermolot-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Supermolot';
	src: url('Supermolot-ThinItalic.eot');
	src: local('Supermolot Thin Italic'), local('Supermolot-ThinItalic'),
		url('Supermolot-ThinItalic.eot?#iefix') format('embedded-opentype'),
		url('Supermolot-ThinItalic.woff') format('woff'),
		url('Supermolot-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}
