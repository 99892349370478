.flat-block {
  background-color: #fff;
  box-shadow: 0 30px 30px rgba(#000, 0.05);
  border-radius: 10px;
  padding: 20px;
  overflow: hidden;

  &_type {
    &_center {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  & + & {
    margin-top: 20px;
  }
}
