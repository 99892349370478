@import "./fonts/Supermolot/stylesheet";
@import "./common/scss/mixins";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  background-color: #141526;
}

#root {
  background: #141526;
  font: 400 14px/1.2142857143 'Supermolot', sans-serif;
  color: #141526;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.app {

  display: flex;
  flex-direction: column;

  &__title-block {
    margin: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 820px;

    @include for-phone-only {
      padding: 20px 16px;
      width: 100%;
      box-sizing: border-box;
      order: 1;
      display: none;
    }
  }

  &__main-panel {
    margin: auto;
    width: 820px;

    @include for-phone-only {
      order: 3;
      padding: 0 16px 20px;
      width: 100%;
      box-sizing: border-box;
      height: auto;
      margin-bottom: 20px;
    }
  }

  &__carousel {
    width: 820px;
    margin: auto;
    padding: 20px 0;
    @include for-phone-only {
      order: 2;
      width: 100%;
      box-sizing: border-box;
      overflow: auto;
      padding-left: 16px;
    }
  }
}

// Overall Styles
.wrapper {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 852px;
  padding-left: 16px;
  padding-right: 16px;
}

.must-sprite {
  display: none;
  pointer-events: none;
}

.page {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  overflow: hidden;
}

.is-fixed {
  overflow: hidden;
}
