@import '~common/scss/mixins';

.must-popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 901;

  &__bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(#000, .5);
    will-change: opacity;
    cursor: pointer;
    animation: fadeIn 0.25s;
  }

  &__container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 90px;
    padding-bottom: 50px;
    z-index: 1;

    @include tablet {
      padding-top: 50px;
    }
  }

  &__inner {
    width: 368px;
    margin: auto;
    background: #fff;
    box-shadow: 0 30px 30px rgba(#000, 0.05);
    border-radius: 10px;
    position: relative;
    animation: runFromBottom 0.6s;
  }

  &__closeSvg {
    position: absolute;
    top: -40px;
    right: -40px;
    cursor: pointer;
    z-index: 1000;
    width: 40px;
  }

  &__disabled {
    opacity: 0.2;
  }
  .must-popup-view {
    padding: 20px 40px 40px 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-between;

    &__item {
      padding-top: 20px;
    }

    &__title {
      display: flex;
      padding-bottom: 6px;
    }

    &__no-border-button {
      background-color: white;
      font-family: Ubuntu;
      font-style: normal;
      font-size: 14px;
      line-height: 20px;

      display: inline;
      text-align: center;
      text-decoration-line: underline;

      color: #27B4B8;
    }

    &__no-border-button:hover {
      background-color: white;
      color: #27B4B8;
    }

    &__digit {
      width: 20px;
    }

    &__countdown-text {
      font-style: normal;
      color: #545454;
    }

    .phone-number__number-input {
      width: 100%;
    }
  }
}

.name__input {
  width: 290px;
  background: #FFFFFF;
  border: 2px solid #23B4BA;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0;
  margin: 0;
  height: 50px;
  border-radius: 6px;
  box-sizing: border-box;
  outline: none;
  padding-left: 20px;
  font-size: 18px;
  color: #3B3A4E;
  font-family: Supermolot;
  font-style: normal;
  line-height: 100%;
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from(hsla(0, 0%, 100%, 0)), to(hsla(0, 0%, 100%, 0)));
  background-image: -webkit-linear-gradient(hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0));
  transition: border-color 0.2s;

  &::placeholder {
    color: rgba(#3b3a4e, 0.2);
  }

  .must-popup-view__item_error & {
    border-color: #d76064;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}



@keyframes runFromBottom {
  0% {
    opacity: 0;
    transform: translateY(16px);
  }

  25% {
    opacity: 0;
    transform: translateY(16px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
