@import "../../common/scss/mixins";

.auto-form {
  background: #F6F7FF;
  width: 330px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 560px;
  border-left: 1px solid #B9BBCD;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include for-phone-only {
    background: #ffffff;
    height: auto;
    width: 100%;
  }

  &__man {
    margin-top: 30px;
    margin-bottom: 20px;
    @include for-phone-only {
      display: none
    }
  }

  &__man-svg {
    width: 97px;
    height: 156px;
  }

  &__car-number-title {
    display: flex;
    flex-direction: row;
    text-align: left;
    width: calc(100% - 20px);
    height: 30px;
    align-items: center;
  }

  &__email-title {
    display: flex;
    flex-direction: row;
    text-align: left;
    width: calc(100% - 20px);
    height: 30px;
    align-items: center;
    margin-top: 20px;
  }

  &__digit {
    width: 20px;
  }

  &__disabled {
    opacity: 0.2;
  }
}