@import "../../common/scss/mixins";
.popup {
    width: 368px;
    margin: auto;
    @include for-phone-only {
      margin-top: 90px;
    }
    background: #FFFFFF;
    box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    position: relative;

    &__container {
        overflow-x: hidden;
        overflow-y: auto;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10000;
      
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &__container.hided{
        display: none !important;
      }
      
    
    &__closeSvg{
      position: absolute;
      top: -40px;
      right: -40px;
      cursor: pointer;
      z-index: 1000;
      width: 40px;
      @include for-phone-only {
        right: calc(50% - 20px);
        top: -85px;
      }
    }
    
    &__disabled {
      opacity: 0.2;
    }
   
  }

  .name__input {
    width: 290px;
    background: #FFFFFF;
    border: 2px solid #23B4BA;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0;
    margin: 0;
    height: 50px;
    box-sizing: border-box;
    outline: none;
    padding-left: 20px;
    font-size: 18px;
    color: #3B3A4E;
    font-family: Supermolot;
    font-style: normal;
    line-height: 100%;
    background-image:-webkit-gradient(linear, 0% 0%, 0% 100%, from(hsla(0,0%,100%,0)), to(hsla(0,0%,100%,0)));
    background-image:-webkit-linear-gradient(hsla(0,0%,100%,0), hsla(0,0%,100%,0));
    -webkit-appearance: none;
  }