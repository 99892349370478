@import "../../common/scss/mixins";

.auto-data {
  width: 330px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 560px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include for-phone-only {
    width: 290px;
    height: auto;
  }



  &__title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    height: 70px;
  }

  &__title {
    text-align: center;
  }

  &__number-title {
    margin-bottom: 10px;
  }

  &__number-container {
    margin-bottom: 50px;
  }

  &__row {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  &__column-1 {
    width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
  }

  &__column-2 {
    height: 30px;
    display: flex;
    align-items: center;
    width: 190px;
  }
}