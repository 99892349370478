@import '../../common/scss/mixins.scss';

.page-profile {
  &__main {
    box-sizing: border-box;

    @include tablet {
      display: flex;
    }
  }

  &-column {
    & + & {
      margin-top: 20px;

      @include tablet {
        margin-top: 0;
      }
    }

    @include tablet {
      width: 50%;
      padding: 0 20px;
    }

    &__item {
      & + & {
        margin-top: 20px;
      }

      .email,
      .phone-number {
        width: 100%;
      }
    }

    &__title {
      line-height: 30px;
    }
  }

  &__subtext {
    font-family: 'Ubuntu', sans-serif;
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
    color: #545454;
  }

  &__input {
    position: relative;

    &-svg {
      position: absolute;
      top: 50%;
      right: 20px;
      margin-top: -10px;
      display: block;
      width: 20px;
      height: 20px;
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.2s;

      &_active {
        opacity: 1;
      }
    }

    input {
      width: 100%;
    }
  }

  &__btn {
    padding-top: 20px;
  }
}