.phone-number {
  width: 290px;
  background: #FFFFFF;
  height: 50px;
  border: 2px solid #23B4BA;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: border-color 0.2s;

  &_error {
    border-color: #D76064;
  }

  .must-popup-view__item_error & {
    border-color: #d76064;
  }

  &__number-input {
    width: 288px;
    border-radius: 2px;
    background: #FFFFFF;
    border: none;
    padding: 0;
    margin: 0;
    height: 46px;
    box-sizing: border-box;
    outline: none;
    font-size: 18px;
    font-family: Supermolot;
    color: transparent;
    caret-color: #3B3A4E;
    text-transform: uppercase;

    padding-left: 22px;
    letter-spacing: 2.37px;
    padding-top: 0;

    &::-webkit-input-placeholder { /* Edge */
      color: #d7d7dc;
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #d7d7dc;
    }

    &::placeholder {
      color: #d7d7dc;
    }


    &:focus{
      padding-left: 50px;
      letter-spacing: 4px;
      font-size: 16px;
    }

    &:-webkit-autofill {
      color: white !important;
    }
  }

  &__number {
    position: absolute;
    z-index: 1;
    display: none;
    flex-direction: row;
    margin-left: 18px;
    cursor: text;
  }

  &__number_focused {
     display: flex;
  }

  &__digits-row {
    display: flex;
    flex-direction: row;
    margin: 0 4px;
  }

  &__phone-character {
    font-family: Supermolot;
    font-style: normal;
    font-size: 18px;
    line-height: 40px;
    text-transform: uppercase;
    color: rgb(215, 215, 220);
    width: 10px;
    height: 40px;
    text-align: center;
  }

  &__left-ellipse {
    position: relative;
    left: 4px;
    width: 8px;
    height: 6px;
  }

  &__right-ellipse {
    position: relative;
    right: 4px;
    width: 8px;
    height: 6px;
  }

  &__active-character {
    color: #3B3A4E
  }

  &__number-hidden {
    font-size: 34px;
    letter-spacing: 13px;
    font-family: Supermolot;
    text-transform: uppercase;
    position: absolute;
    top: -1500px;
  }

  &__rus {
    margin-left: 14px;
    margin-bottom: 4px;
  }

  &__error-bubble {
    position: absolute;
    margin-left: -272px;
  }

  &__error-bubble_small {
    margin-left: -192px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: transparent !important;
  }



}