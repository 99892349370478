@import "../../common/scss/mixins";

.email {
  background: #FFFFFF;
  border: 2px solid #23B4BA;
  box-sizing: border-box;
  border-radius: 4px;
  width: 290px;
  display: flex;
  align-items: center;

  &_error {
    border-color: #D76064;
  }

  &__input {
    width: 288px;
    background: #FFFFFF;
    border: none;
    padding: 0;
    margin: 0;
    height: 46px;
    border-radius: 6px;
    box-sizing: border-box;
    outline: none;
    padding-left: 20px;
    font-size: 18px;
    font-family: Supermolot;
    color: #3B3A4E;
    -webkit-appearance: none;
    &:disabled {
      color: #3B3A4E !important;
      -webkit-text-fill-color: #3B3A4E !important;
      opacity: 1 !important;
    }
  }

  &__error-bubble {
    pointer-events: none;
    position: absolute;
    margin-left: -272px;
    @include for-phone-only {
      margin-left: 23px;
      margin-top: -90px;
    }
  }
}