.sms-code {
  width: 290px;
  background: #FFFFFF;
  height: 50px;
  border: 2px solid #23B4BA;
  border-radius: 4px;
  box-sizing: border-box;
  // border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &_error {
    border-color: #D76064;
  }

  &__smsCode-input {
    width: 286px;
    border-radius: 2px;
    background: #FFFFFF;
    border: none;
    padding: 0;
    margin: 0;
    padding-left: 80px;
    height: 46px;
    box-sizing: border-box;
    outline: none;
    font-size: 34px;
    // letter-spacing: 13px;
    letter-spacing: 12px;
    font-family: Supermolot;
    // color: transparent;
    color:#3B3A4E;
    caret-color: #3B3A4E;
    text-transform: uppercase;
  }

  &__smsCode {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: row;
    margin-left: 80px;
    cursor: text;

  }

  &__digits-row {
    display: flex;
    flex-direction: row;
    margin: 0 4px;
  }

  &__big-character {
    font-family: Supermolot;
    font-style: normal;
    font-size: 34px;
    line-height: 40px;
    text-transform: uppercase;
    color: rgb(215, 215, 220);
    width: 30px;
    height: 40px;
    text-align: center;
  }

  &__left-ellipse {
    position: relative;
    left: 4px;
    width: 8px;
    height: 6px;
  }

  &__right-ellipse {
    position: relative;
    right: 4px;
    width: 8px;
    height: 6px;
  }

  &__active-character {
    color: #3B3A4E
  }

  &__smsCode-hidden {
    font-size: 34px;
    letter-spacing: 13px;
    font-family: Supermolot;
    text-transform: uppercase;
    position: absolute;
    top: -1500px;
  }

  &__rus {
    margin-left: 14px;
    margin-bottom: 4px;
  }

  &__error-bubble {
    position: absolute;
    margin-left: -272px;
  }

  &__error-bubble_small {
    margin-left: -192px;
  }
}