@import "../../common/scss/mixins";

.score-graph {
  width: 490px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @include for-phone-only {
    width: 100%;
  }

  &__title {
    text-align: center;
    margin: 37px auto 60px;
    transition: 0.5s ease-out;

    @include for-phone-only {
      font-size: 22px;
      line-height: 27px;
      margin-top: 26px;
      margin-bottom: 30px;
    }
  }

  &__title_animated {
    margin-bottom: 27px;
    @include for-phone-only {
      margin-bottom: 10px;
      max-width: 240px;
    }
  }

  &__technologies {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
    @include for-phone-only {
      display: none;
    }
  }

  &__logo {
    width: 82px;
    height: 30px;
    margin: 0 10px;
  }

  &__logo-ingos {
    margin-left: 10px;
  }

  &__svg-container {
    position: relative;
  }

  &__arrow-with-circle-svg {
    position: absolute;

    width: 285px;
    top: 0px;
    left: 52px;
    transform-origin: 142px 150px;

    @include for-phone-only {
      width: 216.6px;
      height: 216.6px;
      left: 52px;
      transform-origin: 108.3px 114px;
    }
  }

  &__dotted {
    @include for-phone-only {
      width: 112px;
      height: 42px;
    }
  }

  &__text-group {
    position: absolute;
    top: 100px;
    left: 120px;
    min-width: 148px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include for-phone-only {
      top: 80px;
      left: 88px;
    }
  }

  &__text-group-helpText {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #9E9E9E;
    width: 109px;

    @include for-phone-only {
      font-size: 12.2px;
      width: 83px;
    }
  }

  &__text-group-score-text {
    font-family: Supermolot;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    font-size: 30px;
    line-height: 41px;
    padding-top: 16px;
    padding-bottom: 2px;

    @include for-phone-only {
      font-size: 22px;
      padding-top: 3px;
    }
  }

  &__text-group-score-text-percent {
    font-family: Supermolot;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    font-size: 20px;
    line-height: 41px;
    padding-top: 16px;
    padding-bottom: 2px;

    @include for-phone-only {
      font-size: 22px;
      padding-top: 3px;
    }
  }

  &__text-green {
    color: #27B4B8;
    font-weight: bold;
  }

  &__text-blue {
    color: #4C86EE;
    font-weight: bold;
  }

  &__text-yellow {
    color: #E6C104;
    font-weight: bold;
  }

  &__text-orringe {
    color: #DB8A3D;
    font-weight: bold;
  }

  &__text-red {
    color: #D76064;
    font-weight: bold;
  }

  &__infinite-animation {
    animation: rotate 3s 5s ease;
  }

  @keyframes rotate {
    0% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(-90deg);
    }
    100% {
      transform: rotate(90deg);
    }
  }

  &__main-svg {
    @include for-phone-only {
      height: 228px;
      width: 320px;
    }
  }

  &__number-title {
    line-height: 30px;
    margin-bottom: 10px;
  }

  &__mobile-auto-number {
    display: none;
    @include for-phone-only {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__number {
    width: 290px;
    margin-bottom: 27px;
  }

}