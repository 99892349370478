@import '~common/scss/mixins';

.yandex-share {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  pointer-events: none;
  align-items: center;
  justify-content: flex-end;
  display: none;

  @include desktop {
    display: flex;
  }

  &__catcher {
    pointer-events: auto;
  }

  .ya-share2 {
    .ya-share2__title {
      display: none;
    }

    .ya-share2__item {
      margin: 0;
    }

    .ya-share2__badge {
      border-radius: 0;
    }

    .ya-share2__icon {
      width: 44px;
      height: 44px;
      background-size: 44px 44px;
    }
  }
}