@import "../../common/scss/mixins";

.carousel-item {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 10px 10px 20px 20px;
  width: 260px;
  height: 110px;
  box-sizing: border-box;
  justify-content: space-between;
  @include for-phone-only {
    margin-right: 16px;
  }

  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }

  &__text {
    width: 180px;
    margin-left: 11px;
  }
}