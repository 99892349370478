@import "../../common/scss/mixins";

.car-number {
  width: 290px;
  background: #FFFFFF;
  height: 50px;
  border: 2px solid #23B4BA;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &_error {
    border-color: #D76064;
  }

  &__number-input {
    width: 205px;
    background: #FFFFFF;
    border: none;
    padding: 0;
    margin: 0;
    height: 46px;
    border-radius: 6px;
    box-sizing: border-box;
    outline: none;
    padding-left: 14px;
    font-size: 34px;
    letter-spacing: 13px;
    font-family: Supermolot;
    color: transparent;
    caret-color: #3B3A4E;
    text-transform: uppercase;
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }

  &__number {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: row;
    margin-left: 16px;
    cursor: text;
  }

  &__small-character {
    font-family: Supermolot;
    font-style: normal;
    font-size: 24px;
    line-height: 40px;
    text-transform: uppercase;
    color: rgb(215, 215, 220);
    width: 30px;
    height: 40px;
    text-align: center;
  }

  &__digits-row {
    display: flex;
    flex-direction: row;
    margin: 0 4px;
  }

  &__big-character {
    font-family: Supermolot;
    font-style: normal;
    font-size: 34px;
    line-height: 40px;
    text-transform: uppercase;
    color: rgb(215, 215, 220);
    width: 30px;
    height: 40px;
    text-align: center;
  }

  &__left-ellipse {
    position: relative;
    left: 4px;
    width: 8px;
    height: 6px;
  }

  &__right-ellipse {
    position: relative;
    right: 4px;
    width: 8px;
    height: 6px;
  }

  &__active-character {
    color: #3B3A4E
  }

  &__number-hidden {
    font-size: 34px;
    letter-spacing: 13px;
    font-family: Supermolot;
    text-transform: uppercase;
    position: absolute;
    top: -500px;
  }

  &__region-container {
    width: 78px;
    border-left: 2px solid #23B4BA;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  &__region-container_error {
    border-color: #D76064;
  }

  &__region-input {
    width: 64.25px;
    background: #FFFFFF;
    border: none;
    padding: 0;
    margin: 0;
    height: 25px;
    border-radius: 6px;
    box-sizing: border-box;
    outline: none;
    padding-left: 10px;
    font-family: Supermolot;
    caret-color: #3B3A4E;
    text-transform: uppercase;
    font-size: 21.25px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 5px;
    margin-top: 4px;
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    color: #3B3A4E;

    &:disabled {
      color: #3B3A4E !important;
      -webkit-text-fill-color: #3B3A4E !important;
      opacity: 1 !important;
    }

    &::placeholder {
      color: rgb(215, 215, 220);
    }
  }

  &__rus {
    margin-left: 14px;
    margin-bottom: 4px;
  }

  &__error-bubble {
    position: absolute;
    margin-left: -272px;
    @include for-phone-only {
      margin-left: 23px;
      margin-top: -90px;
    }
  }

  &__error-bubble_small {
    margin-left: -192px;
    @include for-phone-only {
      margin-left: 71px;
      margin-top: -90px;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}