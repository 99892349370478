.popup-inner-view {
    padding: 20px 40px 40px 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-between;

    &__item {
      padding-top: 20px;
    }

    &__title{
      display:flex; 
      padding-bottom: 6px;
    }

    &__no-border-button{
          background-color: white;
      font-family: Ubuntu;
      font-style: normal;
      font-size: 14px;
      line-height: 20px;

      display: inline;
      text-align: center;
      text-decoration-line: underline;

      color: #27B4B8;
    }

    &__no-border-button:hover{
        background-color: white;
        color: #27B4B8;
    }

    &__digit {
        width: 20px;  
    }
    
    &__countdown-text{
      font-style: normal;
      color: #545454;
    }
}