@import "../../common/scss/mixins";

.error-bubble {
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0;
  transition: opacity 1s;

  &__desktop {
    display: flex;
    flex-direction: row;
    align-items: center;
    @include for-phone-only {
      display: none;
    }
  }

  &__mobile {
    display: none;
    @include for-phone-only {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 236px;
      background: #d76064;
      height: 70px;
      border-radius: 10px;
      z-index: 2;
    }
  }

  &__mobile_small {
    @include for-phone-only {
      width: 150px;
    }
  }

  &__rectangle {
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    height: 70px;

    font-family: Supermolot;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    width: 236px;
    box-sizing: border-box;
    position: absolute;

    @include for-phone-only {
      text-align: center;
    }
  }

  &__rectangle_small {
    width: 150px;
  }

  &__shown {
    opacity: 1;
    transition: opacity 0s;
  }

  &__tail {
    position: relative;
    top: 45px;
    transform: rotate(90deg);
  }
}