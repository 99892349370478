.popup-header{
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    height: 230px;
    background: #27B4B8;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  

  &__text-with-lines{
    display: flex;

    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  &__white-line{
    width: 30px;
    height: 1px;
    background-color: white;
    align-self: center;
  }
  
  &__text-container{
    height: 100%;
    padding: 70px 40px 40px 40px; 
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-between;
  }

  &__text{
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
  }

  &__shturmanSvg{
    position: absolute;
    margin-top: -110px;
    color: white;
    align-self: center;
    width: 90px;
    height: 90px;
   
    }
    &__followSvg{
        position: absolute;
        margin-top: 116px;
        
    }  

    &__red{
        background-color: #F27A72;
    }
    &__yellow{
        background-color: #DB8A3D;
    }
}