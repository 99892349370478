@import '~common/scss/mixins';

.tc {
  text-align: center;
}

.header {
  background-color: #fff;
  overflow: hidden;

  @include header-width {
    padding: 20px 16px;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 100;

    &_shadow {
      box-shadow: 0 20px 20px 0 rgba(173, 173, 173, 0.1);

      @include desktop {
        transition: none;
        box-shadow: none;
      }
    }

    @include header-width {
      position: static;
      padding: 0;
    }
  }

  &__burger {
    $burger: &;

    display: block;
    margin: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #ebebeb;
    box-shadow: none;
    cursor: pointer;
    outline: none;
    background: #fff;
    position: relative;

    @include header-width {
      display: none;
    }

    &-line {
      display: block;
      width: 12px;
      height: 2px;
      background-color: #000;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -1px;
      margin-left: -6px;
      pointer-events: none;

      #{$burger}_active & {
        background-color: transparent;
      }

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        height: 2px;
        background-color: #000;
      }

      &::before {
        bottom: 100%;
        margin-bottom: 2px;

        #{$burger}_active & {
          bottom: 0;
          margin-bottom: 0;
          transform: rotate(45deg);
        }
      }

      &::after {
        top: 100%;
        margin-top: 2px;

        #{$burger}_active & {
          top: 0;
          margin-top: 0;
          transform: rotate(-45deg);
        }
      }
    }
  }

  &__inner {
    padding-top: 72px;

    @include header-width {
      padding-top: 0;
      margin: 0 auto;
      display: flex;
      justify-content: center;
    }
    //margin: 0 auto;
    //display: flex;
    //flex-wrap: wrap;
    //
    //@include header-width {
    //  justify-content: center;
    //}
  }

  &__service {
    display: flex;

    &-name {
      margin-left: 10px;
      width: 120px;

      @include tablet {
        margin-left: 14px;
      }

      svg {
        display: block;
        width: 120px;
        height: 40px;
        fill: #27b4b8;
        pointer-events: none;
      }
    }
  }

  &__logo {
    position: relative;
    flex: 0 0 110px;

    &-svg {
      display: block;
      width: 110px;
      height: 40px;
      fill: #fff;
      pointer-events: none;
    }
  }

  &__nav {
    margin: -13px 0 0;
    padding: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    font-family: 'Ubuntu', sans-serif;

    @include header-width {
      margin: -20px 0 -20px 20px;
      padding: 0;
    }

    &-list {
      margin: 0 6px;
      padding: 0;
      display: inline-flex;
      vertical-align: top;

      @include header-width {
        margin: 0;
        display: flex;
        align-items: stretch;
      }

      &_mobile {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    $header-nav-item: #{&}-item;

    &-item {
      margin: 0;
      padding: 13px 10px;
      list-style: none;
      position: relative;
      display: flex;
      align-items: center;

      @include header-width {
        padding: 25px 10px;
      }

      & + & {
        margin-left: 10px;
      }
    }

    &-icon {
      display: block;
      height: 30px;
      margin-right: 10px;

      svg {
        display: block;
        width: 30px;
        height: 30px;
        pointer-events: none;
        fill: #9e9e9e;

        #{$header-nav-item}_active & {
          fill: #27b4b8;
        }
      }
    }

    &-link {
      display: block;
      font-size: 14px;
      font-weight: 300;
      line-height: 16px;
      color: #000;
      text-decoration: none;
      text-transform: uppercase;

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      #{$header-nav-item}_active & {
        color: #27b4b8;
      }
    }
  }

  // Mobile Menu
  &__mmenu {
    position: fixed;
    top: 72px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#000, 0.5);
    z-index: 99;

    &-nav {
      background-color: #fff;
      padding-bottom: 10px;
    }
  }
}