@import "../../common/scss/mixins";

.title {
  font-family: Supermolot;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #141526;
  margin: 0 0 4px 0;

  @include for-phone-only {
    margin-bottom: 0;
  }
}