.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 290px;
  height: 50px;
}

.control {
  font-family: arial;
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
}

.control-checkbox.label{
  margin-left: 16px;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #545454;
}
  .control input {
      position: absolute;
      z-index: -1;
      opacity: 0;
  }
.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 30px;
  width: 30px;
  background: #FFFFFF;
  border: 1px solid #C6C6C6;;
  border-radius: 4px;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  background: #f7f4f4;  
}

.control input:checked ~ .control_indicator {
  background: #f6f7ff;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  background: '#0e6647d';
}
.control input:disabled ~ .control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control_indicator:after {
  box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
}
.control input:checked ~ .control_indicator:after {
  display: block;
}
.control-checkbox .control_indicator:after {
  left: 12px;
  top: 7px;
  width: 6px;
  height: 11px;
  border: solid #23B4BA;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control-checkbox input:disabled ~ .control_indicator:after {
  border-color: #7b7b7b;
}
.control-checkbox .control_indicator::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 4.5rem;
  height: 4.5rem;
  margin-left: -1.3rem;
  margin-top: -1.3rem;
  background: #2aa1c0;
  border-radius: 3rem;
  opacity: 0.6;
  z-index: 99999;
  transform: scale(0);
}
@keyframes s-ripple {
  0% {
      transform: scale(0);
  }
  20% {
      transform: scale(1);
  }
  100% {
      opacity: 0;
      transform: scale(1);
  }
}
@keyframes s-ripple-dup {
 0% {
     transform: scale(0);
  }
 30% {
      transform: scale(1);
  }
  60% {
      transform: scale(1);
  }
  100% {
      opacity: 0;
      transform: scale(1);
  }
}
.control-checkbox input + .control_indicator::before {
  animation: s-ripple 250ms ease-out;
}
.control-checkbox input:checked + .control_indicator::before {
  animation-name: s-ripple-dup;
}